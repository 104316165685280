/* eslint-disable react/no-array-index-key */
import {MongoPassport, PassportType} from '@travelfreedom/shared';
import {graphql, useStaticQuery} from 'gatsby';
import React, {useMemo, useState} from 'react';

import {CountryPill, Layout, SelectPassportType, SEO} from '../components';
import {Passport} from '../library/common';
import {buildRankingBuckets, RankingTableItem, SingleRanking} from '../library/evaluation';

export const RankingPage = (): JSX.Element => {
  const {allMongodbTravelfreedomPassports} = useStaticQuery(graphql`
    query {
      allMongodbTravelfreedomPassports(sort: {fields: name}) {
        nodes {
          globalCode
          name
          ranking {
            rank
            travelfreedom
          }
        }
      }
    }
  `);

  const [passportType, setPassportType] = useState<PassportType>('ORDINARY');

  const passports = useMemo(
    () =>
      allMongodbTravelfreedomPassports.nodes
        .map((node: MongoPassport) => Passport.fromLeanJson(node))
        .filter((d: Passport.Data) => d.type === passportType),
    [passportType, allMongodbTravelfreedomPassports]
  );

  const singleRanking: SingleRanking = useMemo(
    () =>
      Object.assign(
        {},
        ...passports.map((p) => ({
          [p.globalCode]: Passport.getTravelfreedomByCriteria(p, 'COUNTRIES'),
        }))
      ),
    [passports]
  );

  const rankingTable: RankingTableItem[] = useMemo(
    () => buildRankingBuckets(singleRanking, passports),
    [singleRanking, passports]
  );

  const maxRankingValue = 224;
  const rankingCriteriaText = 'Countries';

  return (
    <Layout>
      <SEO title="Rankings" pathname="/ranking" />
      <h1 className="font-bold text-gray-500 mt-3 mb-2 max-w-7xl mx-auto">Rankings</h1>

      <div className="max-w-7xl mx-auto pb-2 flex items-center">
        <div className="mr-2">Document Type:</div>
        <div className="w-64">
          <SelectPassportType
            selectedType="ORDINARY"
            onSelect={(value: PassportType): void => setPassportType(value)}
          />
        </div>
      </div>

      <table className="table-auto w-full max-w-7xl mx-auto mb-2 bg-white">
        <thead className="border divide-y divide-gray-200">
          <tr className="divide-x">
            <th className="p-2">Rank</th>
            <th className="p-2">Name</th>
            <th className="p-2">{rankingCriteriaText}</th>
          </tr>
        </thead>
        <tbody className="border divide-y divide-gray-200">
          {rankingTable.map((rti, index) => (
            <tr
              key={rti.documents.reduce((a, c) => `${a}${c.globalCode}`, '')}
              className="divide-x"
            >
              <td className="text-center p-2">
                <span className="badge badge-secondary">{index + 1}</span>
              </td>
              <td>
                {rti.documents.map((passport) => (
                  <CountryPill
                    key={passport.globalCode}
                    name={passport.name}
                    code={passport.code}
                    href={Passport.hrefPath(passport)}
                  />
                ))}
              </td>
              <td width="20%" className="p-2">
                <div className="overflow-hidden mb-4 flex rounded bg-green-200">
                  <div
                    style={{
                      width: `${(rti.value / maxRankingValue) * 100}%`,
                    }}
                    className="shadow-none flex flex-col text-center whitespace-nowrap 
                      text-white justify-center bg-green-500"
                  >
                    {rti.value}
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Layout>
  );
};

export default RankingPage;
